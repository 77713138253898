import React from "react"
import { Box, Link } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

export default function Footer() {
  return (
    <>
      <Outlet />
      <Box position="fixed" bottom="0" right="5vw" height="2em" color="black">
        &copy; 2025,{" "}
        <Link href="https://blindinsight.com" isExternal>
          Blind Insight
        </Link>{" "}
        |{" "}
        <Link href="https://docs.blindinsight.io" isExternal>
          View docs
        </Link>
      </Box>
    </>
  )
}
