import React, { useState, useCallback } from "react"
import { Form, useParams, useNavigate } from "react-router-dom"
import BIJSONEditor from "./JSONEditor"
import { Api } from "../lib/api"
import {
  Alert,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  ListItem,
  Link,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react"
import { Datasets } from "../lib/store"
import { unrollError } from "../lib/util"

export default function SchemaCreate() {
  const [error, setError] = React.useState(null)
  const [dataset, setDataset] = React.useState({})
  const [datasets] = Datasets.useState()
  const { datasetId } = useParams()
  React.useEffect(() => {
    setDataset(datasets[datasetId])
  }, [datasets])

  const api = new Api()

  // Example schema
  const initialContent = {
    type: "object",
    properties: {
      name: {
        type: "string",
      },
      age: {
        type: "integer",
        minimum: 1,
        maximum: 100,
      },
    },
    required: ["name", "age"],
  }

  // Set the initial content to a pretty indented version that can be immediately submitted.
  const [jsonContent, setJsonContent] = useState({
    json: undefined,
    text: JSON.stringify(initialContent, null, 2),
  })

  const onChangeJSON = useCallback(
    (updatedContent, previousContent, status) => {
      console.log("onChange", { updatedContent, previousContent, status })
      setJsonContent(updatedContent)
      console.log(">>> jsonContent", jsonContent)
    },
    [jsonContent],
  )

  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { name, description } = event.target.elements
    let schema

    // Invalid JSON will not parse.
    try {
      schema = JSON.parse(jsonContent.text)
    } catch (e) {
      setError(
        "JSON schema is invalid. Please correct the errors before submitting.",
      )
      return
    }

    try {
      await api.schemasCreate(
        dataset.url,
        name.value,
        description.value,
        schema,
      )
      setError(null)
      await api.schemasList()
      navigate(`/datasets/${datasetId}`)
    } catch (e) {
      console.log("Failed to create schema", e)
      setError(await unrollError(e))
    }
  }

  return (
    <VStack alignItems={"left"} mt="3em">
      <Heading variant="standardMicro">Add a new Schema</Heading>
      <Form onSubmit={handleSubmit}>
        <HStack alignItems="flex-start" justify="space-between" mr="5em">
          <VStack alignItems="left">
            <Box width="30%">
              <Input
                variant="standardMdl"
                name="name"
                placeholder="Name"
                id="fld-schema-name"
                isRequired
              />
              <Text color="black" fontSize="xs">
                Human-readable name
              </Text>
              <Input
                variant="standardMdl"
                name="description"
                placeholder="Description"
                id="fld-schema-description"
              />
              <Text color="black" fontSize="xs">
                Brief description
              </Text>
            </Box>
            {error && <Alert severity="error">{error}</Alert>}

            <BIJSONEditor
              content={jsonContent}
              onChange={onChangeJSON}
              readOnly={false}
              mode="text"
              mainMenuBar={false}
              id="fld-schema-content"
            />
            <Text fontSize="xs">JSON Schema</Text>

            <UnorderedList>
              <ListItem>
                Use the sample schema above as a guide. Learn more in the{" "}
                <Link
                  textDecoration="underline"
                  href="https://docs.blindinsight.io/overview/what-is-blindinsight/#schemas"
                  isExternal
                >
                  Schema documentation
                </Link>
              </ListItem>
              <ListItem>
                Schemas must be in{" "}
                <Link
                  textDecoration="underline"
                  href="https://json-schema.org/understanding-json-schema/reference"
                  isExternal
                >
                  JSON Schema
                </Link>{" "}
                format and consist of fields with <code>string</code> or{" "}
                <code>number</code> types.
              </ListItem>
              <ListItem>
                Each number field must include values for it&apos;s{" "}
                <code>minimum</code> and <code>maximum</code> bounds.
              </ListItem>
            </UnorderedList>
            <Flex width="full" alignItems="right" gap="2">
              <Button
                buttonStyle="inline"
                title="Close dialogue"
                size="sm"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                text="Create schema"
                size="sm"
              >
                Create
              </Button>
            </Flex>
          </VStack>
        </HStack>
      </Form>
    </VStack>
  )
}
