import React from "react"
import { Form, useNavigate } from "react-router-dom"
import { Api } from "../lib/api"
import { unrollError } from "../lib/util"

import {
  Alert,
  Button,
  FormControl,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react"

import Logo from "../images/horizontal-light.svg"
import { CurrentOrgId } from "../lib/store"
import GradientBox from "./GradientBox"

export default function OrganizationCreate() {
  const [error, setError] = React.useState(null)
  const navigate = useNavigate()
  const api = new Api()
  const currentOrgId = CurrentOrgId.getValue()

  React.useEffect(() => {
    // If the user has already created an organization, redirect to the previous
    // page so that a user can only create a new organization if one does not
    // exist. FOR NOW. This is here to prevent a user from creating multiple
    // organizations AND to prevent a page refresh redirecting to this page
    // constantly. It's ugly, but it works. FOR NOW...
    if (currentOrgId) {
      console.log("Already have a current org id, redirecting to previous page")
      navigate(-1)
    }
  }, [currentOrgId])

  async function handleSubmit(e) {
    e.preventDefault()
    console.log("submit")
    try {
      // Create the organization; get the response
      const org = await api.organizationsCreate(e.target.name.value)
      const orgs = [org]
      api.setOrFindOrganization(orgs)
      navigate("/choose-plan")
    } catch (e) {
      console.log("Error creating organization", e)
      setError(await unrollError(e))
    }
  }

  return (
    <GradientBox>
      <Image src={Logo} width="20vw" alt="Blind Insight Logo" />
      <VStack minWidth="100%" justifyContent="space-between" alignItems="left">
        <Form onSubmit={handleSubmit}>
          <Heading variant="standard">Create an Organization</Heading>
          {error && <Alert severity="error">{error}</Alert>}
          <FormControl mt="3em" display="flex" flexDirection="column">
            <Input
              variant="account"
              name="name"
              placeholder="Name"
              isRequired
            />
            <Text variant="subtext">
              A human-readable name for your organization.
            </Text>
            <VStack spacing="1.5vh" mt="3vh">
              <Button
                variant="primary"
                type="submit"
                title="Create Organization"
              >
                Create
              </Button>
            </VStack>
          </FormControl>
        </Form>
      </VStack>
      <div height="5vh"></div>
    </GradientBox>
  )
}
