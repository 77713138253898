export function isEmpty(value) {
  return (
    value && value.constructor === Object && Object.keys(value).length === 0
  )
}

export function onChange(fn) {
  return (e) => {
    return fn(e.target.value)
  }
}

// TODO(shakefu): samesies
export function onEnter(fn) {
  return (e) => {
    if (e.key !== "Enter") return
    fn()
  }
}

//Will need to be updated once we have a full release product
export function getEnvironment() {
  const fullUrl = window.location.href
  if (fullUrl.includes("demo")) {
    return "Demo"
  } else if (fullUrl.includes("beta") || fullUrl.includes("local")) {
    return "Beta"
  } else {
    return ""
  }
}

// Unroll a error Promise into a human readable message.
export async function unrollError(error) {
  const payload = await error.json()
  if (payload.detail) {
    return payload.detail
  }
  if (payload.error) {
    return payload.error
  }
  // Fallback to the raw JSON error
  return JSON.stringify(payload)
}
